<template>
  <v-container fluid>
    <v-card class="pa-2">
      <h5 class="mb-4">{{$_strings.order.ORDER_DETAIL}}</h5>
      <shipment-group
        :isLoading="isLoadingShipmentGroup"
        :isError="isErrorGetShipmentGroup"
        :item="shipmentGroup"
        @fetchData="fetchShipmentGroup"
      />
      <shipment-details
        :canUpdateStatus="canUpdateStatus"
        :isLoading="isLoadingShipmentDetails"
        :isError="isErrorGetShipmentDetails"
        :item="routeData"
        :isPageOrder="isPageOrder"
        :shipmentGroup="shipmentGroup"
        :itemSequence="findDataSequence"
        :routeForUpdate="routeForUpdate"
        @fetchData="fetchShipmentDetails"
      />
      <btn-update-at-location
        v-if="canUpdateStatus && !isErrorGetShipmentDetails"
        :shipmentOrderNumber="shipmentUpdate.orderNumber"
        :shipment="shipmentUpdate"
        :itemSequence="findDataSequence"
        :routeForUpdate="routeForUpdate"
        :isLoadingShipmentDetails="isLoadingShipmentDetails"
        @fetchData="fetchShipmentDetails"
      />
      <btn-update-cargo
        v-if="canUpdateStatus && !isErrorGetShipmentDetails"
        :itemSequence="findDataSequence"
        :routeForUpdate="routeForUpdate"
        :isLoadingShipmentDetails="isLoadingShipmentDetails"
        @fetchData="fetchShipmentDetails"
      />
      <footer-order-details />
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { generateFunctionUpdateShipment, findSequence } from '@/helper/order.js';

// CHILDREEN COMPONENT
const ShipmentGroup = () => import(/* webpackChunkName: "ShipmentGroup" */ './ShipmentGroup.vue');
const ShipmentDetails = () => import(/* webpackChunkName: "ShipmentDetails" */ './ShipmentDetails.vue');
const FooterOrderDetails = () => import(/* webpackChunkName: "FooterOrderDetails" */ './FooterOrderDetails.vue');
const BtnUpdateCargo = () => import(/* webpackChunkName: "BtnUpdateCargo" */ './BtnUpdateCargo.vue');
const BtnUpdateAtLocation = () => import(/* webpackChunkName: "BtnUpdateAtLocation" */ './BtnUpdateAtLocation.vue');

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  components: {
    ShipmentGroup,
    ShipmentDetails,
    FooterOrderDetails,
    BtnUpdateCargo,
    BtnUpdateAtLocation,
  },
  name: 'order-details',
  created() {
    source = CancelToken.source();
    const { data } = this.$route.params;
    const { status } = this.$route.query;
    if (!status) return this.$router.go(-1);
    if (!data) this.fetchData();
    if (data) {
      const shipmentGroup = { ...data };
      if (data.subOrder) {
        delete shipmentGroup.subOrder;
        this.setRouteData(data.subOrder);
      } else {
        this.fetchShipmentDetails();
      }
      this.shipmentGroup = { ...shipmentGroup };
    }
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      shipmentGroup: {},
      routeData: {},
      statusCargo: ['unconfirmed', 'process', 'finished'],
      isLoadingShipmentGroup: false,
      isErrorGetShipmentGroup: false,
      isLoadingShipmentDetails: false,
      isErrorGetShipmentDetails: false,
    };
  },
  computed: {
    isPageOrder() {
      const path = this.$route.path.split('/')[2];
      const pathPageOrder = ['pesanan', 'update-orders'];
      return pathPageOrder.includes(path);
    },
    shipmentUpdate() {
      if (this.routeData.shipmentResponse) {
        return this.routeData.shipmentResponse[0] || {};
      }
      return {};
    },
    canUpdateStatus() {
      const { listShipments } = this.routeForUpdate;
      const { orderNumber } = this.shipmentUpdate;
      let orderFinished = false;
      if (listShipments && orderNumber) {
        orderFinished = listShipments.includes(orderNumber);
      }
      const { canUpdate } = this.userAccess;
      const { method } = this.$route.params;
      return method === 'update' && canUpdate && !orderFinished;
    },
    routes() {
      return this.routeData.routes || [];
    },
    routeForUpdate() {
      const { shipmentsId } = this.$route.params;
      if (!shipmentsId) return {};
      return this.routes.find((r) => r.shipments && r.shipments.map((s) => s.shipmentsId).includes(+shipmentsId)) || {};
    },
    dataSequence() {
      if (this.canUpdateStatus) return this.generateFunctionUpdateShipment(this.routeForUpdate);
      return [];
    },
    findDataSequence() {
      const {
        currentSequence,
        isCurrentSequenceFinish,
        isCurrentSequenceArrived,
        isCurrentSequenceBASTSubmitted,
      } = this.routeForUpdate;
      const { dataSequence } = this;
      return this.findSequence({
        dataSequence,
        currentSequence,
        isCurrentSequenceFinish,
        isCurrentSequenceArrived,
        isCurrentSequenceBASTSubmitted,
      });
    },
  },
  methods: {
    generateFunctionUpdateShipment,
    findSequence,
    setRouteData(data) {
      const { shipmentsId } = this.$route.params;
      if (shipmentsId) {
        this.routeData = {
          ...data,
          shipmentResponse: data.shipmentResponse.filter((d) => +d.id === +shipmentsId),
        };
        return;
      }
      this.routeData = data;
    },
    fetchShipmentConfirmation() {
      const {
        status,
        cargoTitle,
        shipmentDateStart,
        shipmentDateEnd,
      } = this.$route.query;
      const confirmationType = status.match(/([^_])([a-z])*$/)[0];
      const filters = {
        page: 0,
        size: 1,
        cargoTitle,
        pickupDateFrom: dayjs(shipmentDateStart).format('YYYY-MM-DD'),
        pickupDateTo: dayjs(shipmentDateEnd).add(1, 'day').format('YYYY-MM-DD'),
      };
      return this.$_services.order.getListShipmentsConfirmation(filters, confirmationType, source);
    },
    fetchShipmentCargo(status) {
      const {
        groupTitle,
        shipmentDateStart,
        shipmentDateEnd,
      } = this.$route.query;
      return this.$_services.order.getListShipments({
        page: 0,
        size: 1,
        shipmentNumber: groupTitle,
        shipmentDateStart: dayjs(shipmentDateStart).format('YYYY-MM-DD'),
        shipmentDateEnd: dayjs(shipmentDateEnd).add(1, 'day').format('YYYY-MM-DD'),
        sort: null,
      }, status.toUpperCase(), source);
    },
    async fetchShipmentGroup(indexFetch = 0, _status) {
      try {
        const shipmentConfirmation = ['confirmation_transporter', 'confirmation_driver'];
        const status = _status || this.$route.query.status;
        let service = this.fetchShipmentCargo;
        if (shipmentConfirmation.includes(status.toLowerCase())) service = this.fetchShipmentConfirmation;
        this.isLoadingShipmentGroup = true;
        this.isErrorGetShipmentGroup = false;
        const result = await service(status);
        if (!shipmentConfirmation.includes(status.toLowerCase()) && indexFetch <= 2) {
          if (result.data.contents.length === 0) {
            const nextStatus = this.statusCargo[this.statusCargo.indexOf(status) + 1];
            const _indexFetch = this.statusCargo.indexOf(status) + 1;
            return this.fetchShipmentGroup(_indexFetch, nextStatus);
          }
        }
        this.shipmentGroup = { ...result.data.contents[0] };
      } catch {
        this.isErrorGetShipmentGroup = true;
      } finally {
        this.isLoadingShipmentGroup = false;
      }
    },
    async fetchShipmentDetails() {
      const {
        groupId,
      } = this.$route.params;
      try {
        this.isLoadingShipmentDetails = true;
        this.isErrorGetShipmentDetails = false;
        const result = await this.$_services.order.getListShipmentDetail(groupId, source);
        this.setRouteData(result.data);
      } catch {
        this.isErrorGetShipmentDetails = true;
      } finally {
        this.isLoadingShipmentDetails = false;
      }
    },
    fetchData() {
      this.fetchShipmentGroup();
      this.fetchShipmentDetails();
    },
  },
};

</script>

<style scoped lang="scss">
::v-deep .v-data-table-header {
  tr {
    .amber.accent-3 {
      background-color: #f1c231 !important;
    }
    th:nth-child(1) {
      border-top-left-radius: 5px;
    }
    th:nth-last-child(1) {
      border-top-right-radius: 5px;
    }
  }
}
</style>
