export const statusFinished = () => {
  const status = [
    'barang diterima utuh',
    'ba konfirmasi shipper',
    'BAP - Konfirmasi Shipper',
    'dibatalkan',
    'kedaluwarsa',
    'ditolak',
    'diterima utuh',
  ];
  return status;
};

export const generatePatternSuquence = (route) => {
  const { totalSequence } = route;
  const dataSeq = [
    {
      currentSequence: 0,
      isCurrentSequenceArrived: false,
      isCurrentSequenceFinish: false,
    },
  ];

  const loops = totalSequence * 3;
  let currentSequence = 1;
  const checkTotal = (data, currentSeq) => data.filter((d) => d.currentSequence === currentSeq).length;

  for (let i = 1; i <= loops; i += 1) {
    if (checkTotal(dataSeq, currentSequence) === 0) {
      dataSeq.push({
        currentSequence,
        isCurrentSequenceArrived: false,
        isCurrentSequenceFinish: false,
      });
    } else if (checkTotal(dataSeq, currentSequence) === 1) {
      dataSeq.push({
        currentSequence,
        isCurrentSequenceArrived: true,
        isCurrentSequenceFinish: false,
      });
    } else if (checkTotal(dataSeq, currentSequence) === 2) {
      dataSeq.push({
        currentSequence,
        isCurrentSequenceArrived: true,
        isCurrentSequenceFinish: true,
      });
      currentSequence += 1;
    }
  }
  return dataSeq.map((d) => ({
    ...d,
    isCurrentSequenceBASTSubmitted: false,
  }));
};

export const generateFunctionUpdateShipment = (route) => {
  const {
    multiPick,
    multiDrop,
    totalSequence,
    originLocationId,
    originalLocation,
    destinationLocation,
    destinationLocationId,
    status,
  } = route;
  const multiPickLength = (multiPick && multiPick.length) || 0;
  const multiDropLength = (multiDrop && multiDrop.length) || 0;
  if (route.currentSequence === 0 && statusFinished().includes(status.toLowerCase())) return [];
  const dataSequence = generatePatternSuquence(route);
  const data = [];
  dataSequence.forEach((d, index) => {
    if (d.currentSequence === 0) {
      data.push({
        ...d,
        text: 'Berangkat ke lokasi penjemputan asal',
        locationId: originLocationId,
        notes: originalLocation,
        showBtnUpdateStatus: true,
        status: 'PENJEMPUTAN',
      });
    } else if (d.currentSequence === 1 && !d.isCurrentSequenceArrived) {
      data.push({
        ...d,
        text: 'Sampai di lokasi penjemputan asal',
        locationId: originLocationId,
        notes: originalLocation,
        showBtnUpdateStatus: true,
        status: 'DI_LOKASI_PENJEMPUTAN',
      });
    } else if (
      multiPickLength
      && d.currentSequence + 1 !== totalSequence
      && d.currentSequence <= multiPickLength
      && d.isCurrentSequenceFinish
    ) {
      data.push({
        ...d,
        text: `Berangkat ke lokasi multipick ${d.currentSequence}`,
        locationId: multiPick[d.currentSequence - 1].locationId,
        notes: multiPick[d.currentSequence - 1].name,
        showBtnUpdateStatus: true,
        status: 'PENJEMPUTAN',
      });
    } else if (
      multiPickLength
      && d.currentSequence - 1 <= multiPickLength
      && !d.isCurrentSequenceArrived
    ) {
      data.push({
        ...d,
        text: `Sampai di lokasi multipick ${d.currentSequence - 1}`,
        locationId: multiPick[d.currentSequence - 2].locationId,
        notes: multiPick[d.currentSequence - 2].name,
        showBtnUpdateStatus: true,
        status: 'DI_LOKASI_PENJEMPUTAN',
      });
    } else if (
      multiDropLength
      && d.currentSequence - multiPickLength + 1 !== totalSequence
      && d.currentSequence - multiPickLength <= multiDropLength
      && d.isCurrentSequenceFinish
    ) {
      const sequence = d.currentSequence - multiPickLength;
      const obj = {
        ...d,
        text: `Berangkat ke lokasi multidrop ${sequence}`,
        locationId: multiDrop[sequence - 1].locationId,
        notes: multiDrop[sequence - 1].name,
        showBtnUpdateStatus: true,
        status: 'PENGIRIMAN',
      };
      if (sequence === 1) {
        data.push({
          ...obj,
          isCurrentSequenceBASTSubmitted: false,
        });
      } else if (sequence > 1) {
        data.push({
          ...obj,
          isCurrentSequenceBASTSubmitted: true,
        });
      }
    } else if (
      multiDropLength
      && d.currentSequence - multiPickLength - 1 <= multiDropLength
      && !d.isCurrentSequenceArrived
    ) {
      data.push({
        ...d,
        text: `Sampai di lokasi multidrop ${d.currentSequence - multiPickLength - 1}`,
        locationId: multiDrop[d.currentSequence - multiPickLength - 2].locationId,
        notes: multiDrop[d.currentSequence - multiPickLength - 2].name,
        showBtnUpdateStatus: true,
        status: 'DI_LOKASI_TUJUAN',
      });
    } else if (d.currentSequence + 1 === totalSequence && d.isCurrentSequenceFinish) {
      const obj = {
        ...d,
        text: 'Berangkat ke lokasi pengiriman akhir',
        locationId: destinationLocationId,
        notes: destinationLocation,
        showBtnUpdateStatus: true,
        status: 'PENGIRIMAN',
      };
      if (multiDropLength) {
        data.push({
          ...obj,
          // isCurrentSequenceBASTSubmitted: false,
          isCurrentSequenceBASTSubmitted: true,
        });
      } else {
        data.push({
          ...obj,
          isCurrentSequenceBASTSubmitted: false,
        });
      }
    } else if (d.currentSequence === totalSequence && !d.isCurrentSequenceArrived) {
      data.push({
        ...d,
        text: 'Sampai di lokasi pengiriman akhir',
        locationId: destinationLocationId,
        notes: destinationLocation,
        showBtnUpdateStatus: true,
        status: 'DI_LOKASI_TUJUAN',
      });
    } else {
      data.push(d);
    }
  });

  data.forEach((x, index) => {
    if ((x.status === 'DI_LOKASI_PENJEMPUTAN' || x.status === 'DI_LOKASI_TUJUAN') && data[index + 1]) {
      data.splice(index + 1, 1, {
        ...data[index + 1],
        showBtnSaveAndContinue: true,
      });
    }
  });

  data.forEach((x, index) => {
    if (x.showBtnSaveAndContinue) {
      data.splice(index, 1, {
        ...x,
        locationId: data[index - 1].locationId,
        notes: data[index - 1].notes,
        status: data[index - 1].status,
      });
    }
  });

  data.forEach((x, index) => {
    if (x.status === 'DI_LOKASI_TUJUAN' && x.showBtnSaveAndContinue && data[index + 1]) {
      if (!data[index + 2]) {
        data.splice(index + 1, 1, {
          status: x.status,
          notes: x.notes,
          currentSequence: x.currentSequence,
          isCurrentSequenceBASTSubmitted: x.isCurrentSequenceBASTSubmitted,
          locationId: x.locationId,
          showBtnBAST: true,
          isCurrentSequenceArrived: true,
          isCurrentSequenceFinish: true,
        });
      }
    }
  });

  data.forEach((x, index) => {
    if (x.status === 'DI_LOKASI_TUJUAN' && x.showBtnSaveAndContinue) {
      data.splice(index + 1, 0, {
        status: x.status,
        notes: x.notes,
        currentSequence: x.currentSequence,
        isCurrentSequenceBASTSubmitted: x.isCurrentSequenceBASTSubmitted,
        locationId: x.locationId,
        showBtnBAST: true,
        isCurrentSequenceArrived: true,
        isCurrentSequenceFinish: true,
      });
    }
  });
  return data;
};

export const findSequence = ({
  dataSequence,
  currentSequence,
  isCurrentSequenceFinish,
  isCurrentSequenceArrived,
  isCurrentSequenceBASTSubmitted,
}) => {
  const filterSeq = dataSequence.filter((d) => d.currentSequence === currentSequence
  && d.isCurrentSequenceFinish === isCurrentSequenceFinish
  && d.isCurrentSequenceArrived === isCurrentSequenceArrived
  && d.isCurrentSequenceBASTSubmitted === isCurrentSequenceBASTSubmitted);
  if (filterSeq.length) return filterSeq[filterSeq.length - 1];
  return {};
};
